<template>
  <custom-table
    ref="customTable"
    :columns="columns"
    :custom-view="customView"
    :sums="sums"
    api-url="/pos-goods-list"
    api-download-url="/pos-goods-list-export"
    download-file-name="hang-hoa-cua-hang.xlsx"
  ></custom-table>
</template>

<script>
import { IMAGE_OPTIONS, CUSTOM_VIEW_POS_GOODS } from "@/libs/const";

export default {
  name: "Index",
  data: () => ({
    customView: CUSTOM_VIEW_POS_GOODS,
    imageOptions: [...IMAGE_OPTIONS],
    columns: [],
    sums: [],
  }),
  created() {
    const columns = [
      {
        type: "select-pos",
        label: this.$t("labels.pos"),
        placeholder: this.$t("labels.pos"),
        name: "id_pos",
        hasSort: false,
        sortName: "pos_code",
        key: "pos_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: false,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: false,
        sortName: "sku",
        key: "sku",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.category"),
        placeholder: this.$t("labels.category"),
        name: "category_name",
        hasSort: false,
        sortName: "category_name",
        key: "category_name",
      },
      {
        type: "select-filter",
        label: this.$t("labels.image"),
        placeholder: this.$t("labels.image"),
        name: "image",
        hasSort: false,
        sortName: "url_images",
        key: "url_images",
        options: this.imageOptions,
        required: true,
        component: "Image",
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "description",
        hasSort: false,
        sortName: "description",
        key: "description",
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: false,
        sortName: "size",
        key: "size",
      },
      {
        type: "input-filter",
        label: this.$t("labels.dxrxc"),
        placeholder: this.$t("labels.dxrxc"),
        name: "dxrxc",
        hasSort: false,
        sortName: "dxrxc",
        key: "dxrxc",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.weight"),
        placeholder: this.$t("labels.weight"),
        name: "weight",
        hasSort: false,
        sortName: "weight",
        key: "weight",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.price"),
        placeholder: this.$t("labels.price"),
        name: "price",
        hasSort: false,
        sortName: "price",
        key: "price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.wholesale_price"),
        placeholder: this.$t("labels.wholesale_price"),
        name: "wholesale_price",
        hasSort: false,
        sortName: "wholesale_price",
        key: "wholesale_price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receiving_quantity"),
        placeholder: this.$t("labels.receiving_quantity"),
        name: "receiving",
        hasSort: false,
        sortName: "receiving",
        key: "receiving",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.export_quantity"),
        placeholder: this.$t("labels.export_quantity"),
        name: "total_picked",
        hasSort: false,
        sortName: "total_picked",
        key: "total_picked",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.available_quantity_1"),
        placeholder: this.$t("labels.available_quantity_1"),
        name: "available",
        hasSort: false,
        sortName: "available",
        key: "available",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.reserved_quantity"),
        placeholder: this.$t("labels.reserved_quantity"),
        name: "reserved",
        hasSort: false,
        sortName: "reserved",
        key: "reserved",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.return_quantity"),
        placeholder: this.$t("labels.return_quantity"),
        name: "returning",
        hasSort: false,
        sortName: "returning",
        key: "returning",
        required: true,
      },
    ];
    this.columns = [...columns];

    const sums = [
      {
        text: this.$t("labels.sku"),
        key: "sumSku",
      },
      {
        text: this.$t("labels.avai_sku"),
        key: "sumAvaiSku",
      },
      {
        text: this.$t("labels.available_quantity_1"),
        key: "sumAvailable",
      },
      {
        text: this.$t("labels.receiving_quantity"),
        key: "sumReceiving",
      },
      {
        text: this.$t("labels.export_quantity"),
        key: "sumTotalPick",
      },
      {
        text: this.$t("labels.reserved_quantity"),
        key: "sumReserved",
      },
      {
        text: this.$t("labels.return_quantity"),
        key: "sumReturning",
      },
    ];
    this.sums = [...sums];
  },
};
</script>

<style scoped></style>
